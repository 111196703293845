// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-guide-js": () => import("./../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-cases-js": () => import("./../src/templates/cases.js" /* webpackChunkName: "component---src-templates-cases-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-js": () => import("./../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-guide-js": () => import("./../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

